var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseComponent',[_c('template',{slot:"drawer"},[(_vm.userIsLoggedIn)?_c('v-navigation-drawer',{staticClass:"navigation-menu",staticStyle:{"background-color":"#f9f9f9"},attrs:{"width":"252","clipped":"","app":""}},[_c('v-layout',{attrs:{"fill-height":"","column":"","justify-space-between":""}},[_c('v-list',{staticClass:"pt-0 list-menu",staticStyle:{"background":"inherit !important"},attrs:{"dense":""}},[_c('v-list-tile'),_c('v-list-tile',{staticClass:"list-tile-margin",attrs:{"to":{name: 'candidate:offers'},"active-class":"menu-highlight"}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("mdi-briefcase-outline")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-tile-title"},[_vm._v(" Ofertas ")])],1)],1),_c('v-list-tile',{staticClass:"list-tile-margin",attrs:{"to":{name: 'candidate:profile:applications'},"active-class":"menu-highlight"}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("mdi-account-plus-outline")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-tile-title"},[_vm._v(" Mis Postulaciones ")])],1)],1),_c('v-list-tile',{staticClass:"list-tile-margin",attrs:{"to":{name: 'candidate:profile:favourites'},"active-class":"menu-highlight"}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("far fa-bookmark")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-tile-title"},[_vm._v(" Mis Favoritos ")])],1)],1),_c('v-list-tile',{staticClass:"list-tile-margin",attrs:{"to":{name: 'candidate:profile:detail'},"active-class":"menu-highlight"}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("mdi-account-circle-outline")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-tile-title"},[_vm._v(" Mi Perfil ")])],1)],1),_c('v-list-tile',{staticClass:"list-tile-margin",attrs:{"href":"https://mandomedio.com/outplacement-personas/","target":"_blank"},on:{"click":function($event){return _vm.gaEvent(
                'asesoriaEmpleabilidad',
                'Asesoria Empleabilidad',
                'Asesoria Empleabilidad',
                1
              )}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("mdi-clipboard-list-outline")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-tile-title"},[_vm._v(" Asesoría Empleabilidad ")])],1)],1),_c('v-list-tile',{staticClass:"list-tile-margin",attrs:{"href":"https://mandomedio.com/tips/","target":"_blank"},on:{"click":function($event){return _vm.gaEvent(
                'tips',
                'Tips y Consejos',
                'Tips y Consejos',
                1
              )}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("mdi-briefcase-variant-outline")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-tile-title"},[_vm._v(" Tips y Consejos ")])],1)],1)],1),(false)?_c('v-btn',{attrs:{"color":"success","depressed":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-headset ")]),_vm._v(" Ayuda y Soporte ")],1):_vm._e()],1)],1):_vm._e()],1),_c('template',{slot:"footer"},[_c('div')])],2)}
var staticRenderFns = []

export { render, staticRenderFns }