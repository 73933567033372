<template>
  <BaseComponent>
    <template slot="drawer">
      <v-navigation-drawer
        v-if="userIsLoggedIn"
        width="252"
        class="navigation-menu"
        style="background-color: #f9f9f9"
        clipped
        app
      >
        <v-layout
          fill-height
          column
          justify-space-between
        >
          <v-list
            class="pt-0 list-menu"
            style="background: inherit !important;"
            dense
          >
            <v-list-tile />

            <v-list-tile
              :to="{name: 'candidate:offers'}"
              active-class="menu-highlight"
              class="list-tile-margin"
            >
              <v-list-tile-action>
                <v-icon>mdi-briefcase-outline</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title
                  class="list-tile-title"
                >
                  Ofertas
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              :to="{name: 'candidate:profile:applications'}"
              active-class="menu-highlight"
              class="list-tile-margin"
            >
              <v-list-tile-action>
                <v-icon>mdi-account-plus-outline</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title
                  class="list-tile-title"
                >
                  Mis Postulaciones
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              :to="{name: 'candidate:profile:favourites'}"
              active-class="menu-highlight"
              class="list-tile-margin"
            >
              <v-list-tile-action>
                <v-icon>far fa-bookmark</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title
                  class="list-tile-title"
                >
                  Mis Favoritos
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              :to="{name: 'candidate:profile:detail'}"
              active-class="menu-highlight"
              class="list-tile-margin"
            >
              <v-list-tile-action>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title
                  class="list-tile-title"
                >
                  Mi Perfil
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              href="https://mandomedio.com/outplacement-personas/"
              target="_blank"
              class="list-tile-margin"
              @click="
                gaEvent(
                  'asesoriaEmpleabilidad',
                  'Asesoria Empleabilidad',
                  'Asesoria Empleabilidad',
                  1
                )
              "
            >
              <v-list-tile-action>
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title
                  class="list-tile-title"
                >
                  Asesoría Empleabilidad
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              href="https://mandomedio.com/tips/"
              target="_blank"
              class="list-tile-margin"
              @click="
                gaEvent(
                  'tips',
                  'Tips y Consejos',
                  'Tips y Consejos',
                  1
                )
              "
            >
              <v-list-tile-action>
                <v-icon>mdi-briefcase-variant-outline</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title
                  class="list-tile-title"
                >
                  Tips y Consejos
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-btn
            v-if="false"
            color="success"
            depressed
            small
          >
            <v-icon left>
              mdi-headset
            </v-icon>
            Ayuda y Soporte
          </v-btn>
        </v-layout>
      </v-navigation-drawer>
    </template>
    <template slot="footer">
      <div />
    </template>
  </BaseComponent>
</template>

<script>
import { JwtIsExpired } from '@/utils/jwt';
import BaseComponent from './Base.vue';

export default {
  name: 'ProfileBase',
  components: {
    BaseComponent,
  },
  computed: {
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
  methods: {
    gaEvent($action, $category, $label, $value) {
      this.$ga.event($action, $category, $label, $value);
    },
  },
};
</script>

<style>
  .navigation-menu{
    background-color: #f9f9f9;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #F0EFF3;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #F0EFF3;
  }
  .menu-highlight {
    background-color: #f1f1f1 !important;
    border-radius: 6px;
    height: 60px;
    width: 220px;
    color: #EE8146 !important;
  }
  .list-tile-title {
    height: 32px;
    color: rgba(0,0,0,0.6);
    font-family: Lato;
    font-size: 18px;
    letter-spacing: 0.14px;
    line-height: 32px;
  }
  .list-tile-title-highlight{
    height: 32px;
    color: #EE8146;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.14px;
    line-height: 32px
  }
  .list-tile-margin{
    margin-left: 16px;
    margin-right: 16px;
    height: 60px;
  }
</style>
